import './main_page.css'

export function MainPage() {
    return (
        <div className='mp-root'>
            <div className='mp-center'>
                <div className='mp-content'>
                    <span className='mp-logo-main'>
                        HMSTRS
                    </span>
                    <span className='mp-logo-labs'>
                        /labs
                    </span>
                    <div className='mp-mail'>
                        <a className='mp-link' href="mailto:labs@hmstrs.com">labs@hmstrs.com</a>
                    </div>
                </div>
            </div>
        </div>
    );
}